// @ts-nocheck
/* eslint-disable */
/* tslint:disable */
/* prettier-ignore-start */
import React from "react";
import { classNames } from "@plasmicapp/react-web";

export function _1SvgIcon(props) {
  const { className, style, title, ...restProps } = props;
  return (
    <svg
      xmlns={"http://www.w3.org/2000/svg"}
      fill={"none"}
      viewBox={"0 0 589 667"}
      height={"1em"}
      width={"1em"}
      style={{
        fill: "currentcolor",
        ...(style || {}),
      }}
      className={classNames("plasmic-default__svg", className)}
      {...restProps}
    >
      {title && <title>{title}</title>}

      <path
        d={
          "M475.24 324.053c-10.573-6.458-22.765-9.682-35.14-9.948-18.041-.38-35.416 7.141-48.755 19.303-26.604 24.255-47.906 41.093-96.677 41.093-48.792 0-70.927-17.312-96.287-40.89-11.656-10.839-26.432-18.298-42.312-19.35-14.672-.968-29.458 2.146-41.973 9.792C76.435 347.053.366 417.835.366 614.36c0 7.75.12 15.224.34 22.484.484 16.281 13.937 29.172 30.223 29.172H132.4c-2.443-1.089-4.042-3.615-4.042-9.693 0-10.541 4.719-10.541 10.542-10.541 0 0-11.006-4.792-11.579-10.74l-17.792-184.533c-.708-7.323 4.714-13.297 12.11-13.297h348.146c7.396 0 12.813 5.974 12.11 13.297l-17.792 184.533c-.573 5.948-11.578 10.74-11.578 10.74 5.823 0 10.541.854 10.541 10.541 0 5.953-1.791 8.558-4.515 9.693h99.864c16.286 0 29.74-12.886 30.224-29.167.213-7.26.338-14.74.338-22.489 0-196.52-76.068-267.307-113.729-290.307h-.007z"
        }
        fill={"currentColor"}
      ></path>

      <path
        d={
          "M295.707 528.014c-12.667 0-22.812 9.739-22.667 21.609.146 11.719 10.292 21.109 22.667 21.109 12.375 0 22.521-9.39 22.666-21.109.151-11.875-10-21.609-22.666-21.609zm-105.08-267.387c5.057 5.297 10.515 10.172 16.323 14.651 23.806 19.74 54.369 31.62 87.713 31.62 33.344 0 63.907-11.875 87.713-31.615a145.067 145.067 0 0017.282-15.708c55.406 24.047 109.609 7.609 109.609 7.609s-38.521-15.874-52.125-110.125C441.975 51.996 372.72.646 296.355.646c-76.36 0-145.613 51.349-160.787 156.413-13.604 94.251-52.125 110.125-52.125 110.125s52.625 15.974 107.172-6.557h.012z"
        }
        fill={"currentColor"}
      ></path>
    </svg>
  );
}

export default _1SvgIcon;
/* prettier-ignore-end */
