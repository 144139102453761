// @ts-nocheck
/* eslint-disable */
/* tslint:disable */
/* prettier-ignore-start */
import React from "react";
import { classNames } from "@plasmicapp/react-web";

export function _2SvgIcon(props) {
  const { className, style, title, ...restProps } = props;
  return (
    <svg
      xmlns={"http://www.w3.org/2000/svg"}
      fill={"none"}
      viewBox={"0 0 454 732"}
      height={"1em"}
      width={"1em"}
      style={{
        fill: "currentcolor",
        ...(style || {}),
      }}
      className={classNames("plasmic-default__svg", className)}
      {...restProps}
    >
      {title && <title>{title}</title>}

      <path
        d={
          "M43.312 188.16c0 11.828-9.589 21.437-21.438 21.437-11.849 0-21.437-9.609-21.437-21.437 0-52.776 26.005-100.307 68.073-134.467C109.344 20.516 165.437 0 227.124 0c61.693 0 117.781 20.521 158.613 53.693 42.068 34.162 68.073 81.694 68.073 134.467 0 31.943-9.588 62.047-26.541 88.479-17.183 26.792-41.979 49.708-71.995 66.776-23.656 13.442-43.636 22.265-61.109 29.973-46.505 20.521-71.771 31.651-83.912 113.656-1.703 11.693-12.588 19.803-24.281 18.079-11.693-1.703-19.782-12.589-18.079-24.282 15.636-105.458 48.495-119.953 109.047-146.653 16.776-7.391 36.041-15.88 57.254-27.953 23.97-13.64 43.636-31.719 57.079-52.708 12.568-19.579 19.667-41.823 19.667-65.364 0-39.292-19.938-75.131-52.172-101.318-33.443-27.172-79.97-43.973-131.646-43.973-51.677 0-98.202 16.803-131.645 43.973-32.235 26.188-52.172 62.027-52.172 101.318l.007-.003zm132.494 363.333c24.864 0 47.402 10.078 63.683 26.386 16.307 16.306 26.385 38.817 26.385 63.708 0 24.864-10.078 47.401-26.385 63.682-16.307 16.307-38.817 26.386-63.683 26.386-24.885 0-47.401-10.078-63.708-26.386-16.306-16.307-26.385-38.817-26.385-63.682 0-24.886 10.078-47.402 26.385-63.708 16.307-16.307 38.818-26.386 63.708-26.386zm33.375 56.694c-8.536-8.537-20.339-13.823-33.375-13.823-13.036 0-24.844 5.286-33.401 13.823-8.536 8.536-13.823 20.338-13.823 33.401 0 13.036 5.287 24.844 13.823 33.375 8.536 8.536 20.339 13.822 33.401 13.822 13.037 0 24.844-5.286 33.375-13.822 8.536-8.537 13.823-20.339 13.823-33.375 0-13.036-5.287-24.844-13.823-33.401z"
        }
        fill={"currentColor"}
      ></path>
    </svg>
  );
}

export default _2SvgIcon;
/* prettier-ignore-end */
