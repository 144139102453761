// This is a skeleton starter React component generated by Plasmic.
// This file is owned by you, feel free to edit as you see fit.
import * as React from "react";
import {useState, useEffect} from "react"
import {PlasmicContactSection} from "./plasmic/elementary/PlasmicContactSection";

const Airtable = require('airtable');

function ContactSection_(props, ref) {
    const [email, setEmail] = useState('')
    const [message, setMessage] = useState('')
    const [clicked, setClicked] = useState(false)

    Airtable.configure({
        endpointUrl: 'https://api.airtable.com',
        apiKey: "key5t3q3H8TgvBOMs"
    });
    var base = Airtable.base('appCtJjqbHsY4C6uE');

    const url = 'https://api.airtable.com/v0/appldDJDdY8eUHXyx/tbllxQ7KFFhtwQ29R'

    async function sendDataToAirTable() {
        await base('ContactForm').create([
            {
                "fields": {
                    'Message': message,
                    'Email': email,
                    'Time': new Date(Date.now()).toDateString()
                }
            },

        ], function (err, records) {
            if (err) {
                console.error(err);
                return;
            }
            setMessage('')
            setEmail('')
            setClicked(true)
            records.forEach(function (record) {
                console.log(record.getId());
            });
        });
    }

    return <PlasmicContactSection root={{ref}} {...props}
                                  message={{
                                      autoFocus: false,
                                      value: message,

                                      onChange: (e) => {
                                          setMessage(e.target.value);
                                      },
                                  }}

                                  email={{
                                      autoFocus: false,
                                      value: email,

                                      onChange: (e) => {
                                          setEmail(e.target.value);
                                      },
                                  }}


                                  formButton={{
                                      onClick: () => {
                                          sendDataToAirTable()
                                      },
                                      clicked: clicked
                                  }
                                  }

    />;
}

const ContactSection = React.forwardRef(ContactSection_);

export default ContactSection;
