// @ts-nocheck
/* eslint-disable */
/* tslint:disable */
/* prettier-ignore-start */
import React from "react";
import { classNames } from "@plasmicapp/react-web";

export function _3SvgIcon(props) {
  const { className, style, title, ...restProps } = props;
  return (
    <svg
      xmlns={"http://www.w3.org/2000/svg"}
      fill={"none"}
      viewBox={"0 0 1064 1050"}
      height={"1em"}
      width={"1em"}
      style={{
        fill: "currentcolor",
        ...(style || {}),
      }}
      className={classNames("plasmic-default__svg", className)}
      {...restProps}
    >
      {title && <title>{title}</title>}

      <path
        d={
          "M844.251 1049.12C723.357 1049.12 625 950.759 625 829.866c0-120.894 98.357-219.251 219.251-219.251 39.145 0 77.547 10.455 111.048 30.232l-7.911 13.393c-31.105-18.369-66.774-28.077-103.138-28.077-112.322 0-203.702 91.381-203.702 203.702 0 112.322 91.381 203.705 203.702 203.705s203.7-91.384 203.7-203.705c0-64.508-29.54-123.799-81.046-162.672l9.369-12.413c55.427 41.838 87.227 105.656 87.227 175.082 0 120.893-98.356 219.248-219.249 219.248v.01zm5.512-79.157c-20.279 0-36.778-21.41-36.778-47.728 0-26.319 16.499-47.729 36.778-47.729 20.278 0 36.777 21.41 36.777 47.729 0 26.318-16.499 47.728-36.777 47.728zm0-79.909c-11.511 0-21.229 14.737-21.229 32.18 0 17.443 9.718 32.179 21.229 32.179 11.51 0 21.228-14.736 21.228-32.179s-9.718-32.18-21.228-32.18z"
        }
        fill={"currentColor"}
      ></path>

      <path
        d={
          "M923.802 820.721c-17.446 0-32.715-11.247-36.299-26.744-.964-4.183 1.64-8.359 5.824-9.323a7.766 7.766 0 019.331 5.823c1.966 8.515 10.857 14.695 21.144 14.695 10.288 0 19.186-6.18 21.153-14.695a7.769 7.769 0 019.331-5.823c4.183.964 6.787 5.14 5.823 9.323-3.584 15.496-18.851 26.744-36.306 26.744h-.001zm-148.084.622c-17.454 0-32.722-11.248-36.305-26.745-.965-4.183 1.639-8.359 5.823-9.323a7.76 7.76 0 019.331 5.823c1.966 8.515 10.865 14.695 21.152 14.695 10.288 0 19.178-6.18 21.145-14.695a7.77 7.77 0 019.331-5.823c4.183.964 6.787 5.14 5.823 9.323-3.583 15.496-18.851 26.745-36.299 26.745h-.001zm74.043 148.619c-20.279 0-36.778-21.41-36.778-47.729 0-26.318 16.499-47.728 36.778-47.728 20.278 0 36.777 21.41 36.777 47.728 0 26.319-16.499 47.729-36.777 47.729zm0-79.909c-11.51 0-21.229 14.736-21.229 32.18 0 17.443 9.719 32.179 21.229 32.179s21.228-14.736 21.228-32.179c0-17.444-9.718-32.18-21.228-32.18zm78.94-169.322h-26.633a7.786 7.786 0 01-7.137-4.684 7.778 7.778 0 011.465-8.405l14.372-15.34h-8.511a7.772 7.772 0 01-7.774-7.775 7.772 7.772 0 017.774-7.774h26.444a7.787 7.787 0 017.137 4.684 7.778 7.778 0 01-1.465 8.405l-14.372 15.34h8.7a7.772 7.772 0 017.775 7.775 7.772 7.772 0 01-7.775 7.774zm51.554-51.972h-41.484a7.786 7.786 0 01-7.137-4.684 7.776 7.776 0 011.465-8.405l29.215-31.182h-23.248a7.772 7.772 0 01-7.774-7.774 7.772 7.772 0 017.774-7.775h41.188c3.098 0 5.9 1.842 7.137 4.685a7.777 7.777 0 01-1.465 8.405l-29.215 31.181h23.544a7.772 7.772 0 017.775 7.775 7.772 7.772 0 01-7.775 7.774z"
        }
        fill={"currentColor"}
      ></path>

      <path
        d={
          "M1035.7 615.4h-59.837a7.785 7.785 0 01-7.136-4.685 7.775 7.775 0 011.465-8.404l47.568-50.762h-41.464a7.773 7.773 0 01-7.775-7.775 7.773 7.773 0 017.775-7.774h59.404c3.1 0 5.9 1.841 7.13 4.684a7.771 7.771 0 01-1.46 8.405l-47.566 50.762h41.896c4.3 0 7.77 3.481 7.77 7.774a7.767 7.767 0 01-7.77 7.775zM664.125 0c-99.065 0-179.66 80.595-179.66 179.66s80.595 179.66 179.66 179.66 179.66-80.595 179.66-179.66S763.19 0 664.125 0zm0 323.405c-79.255 0-143.745-64.484-143.745-143.745 0-79.261 64.487-143.745 143.745-143.745 79.258 0 143.745 64.487 143.745 143.745 0 79.258-64.487 143.745-143.745 143.745z"
        }
        fill={"currentColor"}
      ></path>

      <path
        d={
          "M681.797 150.983c.145.919.284 1.834.284 2.795v78.314c0 9.915-8.041 17.957-17.956 17.957-9.916 0-17.957-8.042-17.957-17.957v-78.321c0-1.529-.192-18.912 5.447-23.967l55.569-49.815a17.874 17.874 0 0111.98-4.585 17.93 17.93 0 0113.38 5.971c6.62 7.388 6.002 18.736-1.383 25.356l-49.364 44.252zM642.993 612.72L499.14 363.64a23.352 23.352 0 00-14.161-10.849 23.35 23.35 0 00-17.688 2.328L144.477 541.666a22.626 22.626 0 00-9.567 10.614l-38.151 22.167a11.666 11.666 0 005.953 21.818 11.683 11.683 0 005.834-1.63l29.636-17.266 36.052 62.416-.006-.005c-1.656.115-3.26.635-4.666 1.521l-7.698 4.432c-5.558 3.203-7.485 10.292-4.318 15.864a11.637 11.637 0 007.094 5.484c3 .813 6.198.391 8.89-1.166l7.698-4.432a10.873 10.873 0 003.615-3.266l36.281 62.885.005-.005c-1.656.115-3.26.635-4.666 1.516l-7.698 4.432-.005.005a11.662 11.662 0 00-5.328 13.005 11.66 11.66 0 0011.046 8.693 11.642 11.642 0 005.719-1.516l7.698-4.432a10.878 10.878 0 003.615-3.271l46.666 80.031-29.636 17.151.006.005a11.664 11.664 0 005.833 21.812 10.833 10.833 0 005.833-1.63l39.432-22.749c4.094.062 8.125-.99 11.667-3.037l323.173-186.666a23.313 23.313 0 0010.792-14.11 23.336 23.336 0 00-2.276-17.62l-.007.004zm-153.76-186.667l-84 175.814v-.006a11.663 11.663 0 01-10.5 6.652h-.698l-198.333-12.599c-6.448-.386-11.354-5.922-10.969-12.365.386-6.448 5.922-11.354 12.365-10.969l191.093 11.667 80.615-168.813h.005a11.648 11.648 0 016.651-6.177 11.645 11.645 0 019.068.458 11.657 11.657 0 015.281 15.87l-.578.468zM82.287 596.267a11.677 11.677 0 00-7.12-5.417c-3-.787-6.187-.349-8.864 1.219l-60.552 35c-5.51 3.245-7.386 10.317-4.198 15.864a11.652 11.652 0 007.094 5.484c3 .813 6.198.391 8.89-1.167l60.552-35h-.005a11.669 11.669 0 005.422-7.119c.787-3 .35-6.188-1.219-8.865v.001z"
        }
        fill={"currentColor"}
      ></path>

      <path
        d={
          "M147.274 667.44a11.69 11.69 0 00-7.12-5.416c-3-.787-6.192-.349-8.864 1.219l-60.552 35c-5.552 3.203-7.485 10.291-4.318 15.863a11.65 11.65 0 007.094 5.485c3 .812 6.203.391 8.89-1.167l60.552-35a11.691 11.691 0 005.485-7.094c.807-3 .39-6.197-1.167-8.89zm46.893 81.32c-3.24-5.511-10.318-7.386-15.864-4.198l-60.667 35c-5.557 3.203-7.484 10.286-4.317 15.864a11.684 11.684 0 007.093 5.484c3 .813 6.203.391 8.891-1.166l60.667-35a11.659 11.659 0 005.416-7.12c.792-3 .349-6.188-1.218-8.865l-.001.001zm23.574 105.467l-60.667 35a11.662 11.662 0 00-5.516 13.161 11.65 11.65 0 0011.349 8.651 10.83 10.83 0 005.834-1.63l60.666-35c5.516-3.245 7.386-10.323 4.203-15.87-3.208-5.552-10.291-7.479-15.869-4.312z"
        }
        fill={"currentColor"}
      ></path>
    </svg>
  );
}

export default _3SvgIcon;
/* prettier-ignore-end */
